<template>
  <div>
    <el-card>
      <el-row>
				<el-form :inline="true" class="demo-form-inline">
						<el-col :span="20" class="left">
              <el-form-item label="输入查询">
                <el-input v-model="searchText" placeholder="请输入申请人姓名/手机号" clearable></el-input>
              </el-form-item>
              <el-form-item >
                <el-date-picker v-model="datePicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="search" icon="el-icon-search" size="small">搜索</el-button>
                <el-button type="default" @click="reset" icon="el-icon-refresh" size="small">重置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="4" class="right">
              <el-form-item>
                <el-button type="info" @click="sort" icon="el-icon-sort" size="small"></el-button>
              </el-form-item>
            </el-col>
					</el-form>
			</el-row>
      <el-divider></el-divider>
      <el-table
        :data="detaillist"
        style="width: 100%"
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
        <el-table-column prop="agent_id" label="ID"></el-table-column>
        <el-table-column prop="real_name" label="名称"></el-table-column>
        <el-table-column label="头像">
          <template slot-scope="scope">
            <div>
              <img :src="scope.row.avatarUrl" alt="" style="width:40px;height:40px;border-radius:5px">
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="agent_rank" label="排名"></el-table-column>
        <el-table-column prop="agent_level" label="等级"></el-table-column>
        <el-table-column prop="zhuce_total" label="注册人数"></el-table-column>
        <el-table-column prop="shouyi_total" label="业绩"></el-table-column>
        <el-table-column prop="phoneNumber" label="电话号"></el-table-column>
      </el-table>
      <Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
    </el-card>
  </div>
</template>

<script>
let that;
import Pagination from '@/components/Pagination/Pagination.vue'
export default {
  components:{
    Pagination
  },
  data () {
    return {
      queryInfo: {
        query: "",
        pagenum: 1,
        pagesize: 10,
      },
      total:0,
      searchText:'',
      datePicker:'',
      orderby:true,
      detaillist:[]
    }
  },
  created() {
    that = this;
  	if(!this.$store.state.userInfo) {
			// 防止未登录
			this.$router.push({
				path: '/login'
			})
		} else {
			this.getYaoqingList();
		}
  },
  methods: {
  	getYaoqingList(){
  		var url = 'statistic/yaoqing_list';
			let params = {
				agent_type: 1,
				phone: this.searchText,
				date_time: this.datePicker,
				size: this.queryInfo.pagesize,
				page: this.queryInfo.pagenum,
			};
			this.fd_post(url, params).then((res) => {
				if(res.status) {
					that.total = res.total;
					that.detaillist = res.list;
				} else {
					that.$message.error(res.msg);
				}
			}).catch((err) => {
				that.$message.error('网络错误');
			});
  	},
    search(){
			this.getYaoqingList();
    },
    reset(){
      this.searchText = '';
      this.datePicker = '';
      this.getYaoqingList();
    },
    sort(){
      this.orderby = !this.orderby;
    },
    //页数改变
    handleSizeChange(newSize) {
      this.queryInfo.pagenum = 1;
      this.queryInfo.pagesize = newSize;
      this.getYaoqingList();
    },
    //页码改变
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.getYaoqingList();
    },
  },
}
</script>

<style lang='scss' scoped>
	@import "./Agent_tj_detail.scss";
</style>